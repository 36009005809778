  .poke-item-list {
    position: relative;
    background:"white";
  }

  #poke-id{
    position: absolute;
    top: 0;
    font-size: 16px; /* Tamaño de fuente por defecto */
    transition: font-size 0.2s; /* Transición suave */
  }
  .poke-name {
    font-size: 16px; /* Tamaño de fuente por defecto */
    transition: font-size 0.2s; /* Transición suave */
  }

  .poke-img {
    width: 30%;
    transition: width 0.4s; /* Transición suave */
  }

  .poke-row:hover .poke-name{
    font-size: 20px;
  }
  .poke-row:hover .poke-img{
    width: 50%;

  }